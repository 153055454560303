const check_recaptcha = () => {
  $("#btn_sent").data("checked", true)
}

const checkRecaptchaConditionedSubmit = () => {
  $("button.recaptcha-conditioned-submit").data("checked", true);
  $("button.recaptcha-conditioned-submit").removeClass('disabled');
}

const errorCallbackConditionedSubmit = () => {
  $("button.recaptcha-conditioned-submit").data("checked", false);
  $("button.recaptcha-conditioned-submit").addClass('disabled');
}

export {check_recaptcha, checkRecaptchaConditionedSubmit, errorCallbackConditionedSubmit}